import axios from 'axios'

export class LogsServiceAPI {

  salvarLog(cookie, id_ferramenta, id_acao){

    const token = this.getToken(cookie);
    if (token) {

        //get Axios
        return axios.post('https://painel-acessos-api.econeteditora.com.br/api/log/', 
        {
          id_ferramenta: id_ferramenta,
          id_usuario: token["id"],
          ip_usuario: token["ip_usuario"],
          bl_interno: token["bl_interno"],
          id_acao_usuario: id_acao,
          data: new Date(new Date().getTime() -10800000),
        }, {
          headers: { "x-api-key": "xSj9z9wiTh5JsTxdwhOoW7QplVm8CZ2g2cbl2Nsu" }
        });
    }
  }

  getToken(cookie) {
    let token = "";
    const cookieEconet = cookie.get("bG9naW4");
    if (cookieEconet !== null && cookieEconet !== "") {
      const strToken = window.atob(cookieEconet);
      if (typeof strToken == "string") {
        token = JSON.parse(strToken);
      }
    }
    return token;
  }
}
